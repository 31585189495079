<template>
    <v-dialog v-model="show" @keydown.esc="$emit('close')" @keydown.enter="onConfirm()" persistent max-width="800">
        <v-card>
            <!-- <v-card-title class="headline primary white--text">{{ title }}</v-card-title> -->

            <v-card-text class="my-12 text-h6 text-center">
                Digite os 4 últimos digítos do cartão utilizado.

                <div class="ma-auto position-relative" style="max-width: 300px">
                    <v-otp-input length="4" type="number" v-model="code" @finish="onConfirm" />
                </div>

                <v-alert class="mt-1 mb-0 " text color="info">
                    <div class="text-subtitle-1">
                        Carregamento desbloqueado por meio de pagamento.
                        <br>
                        <br>
                        <strong>Para 'Encerrar', precisamos te identificar.</strong>
                    </div>
                </v-alert>
            </v-card-text>

            <v-divider />

            <v-card-actions class="justify-center">
                <!-- <v-spacer></v-spacer> -->
                <v-btn color="success" @click="onConfirm" style="min-width: 25%;" :disabled="sending">
                    <v-icon dark left>check_circle</v-icon>
                    Confirmar
                </v-btn>
                <v-btn color="error" @click="$emit('close')" style="min-width: 25%;" :disabled="sending">
                    <v-icon dark left>cancel</v-icon>
                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "StopChargerByCode",

    props: {
        show: {
            type: Boolean,
            required: true,
        },
        charger: {
            required: true,
        },
        chargerName: {
            required: true,
        },
        plug: {
            required: true,
        },
    },

    data() {
        return {
            sending: false,
            code: null,
        };
    },
    methods: {
        onConfirm() {
            if (this.code.length == 4) {
                this.sending = true;
                this.$http
                    .postDynamic(this.charger.server, "api/v2/stopByCode", { charger: this.chargerName, plug: this.plug.plug, code: this.code })
                    .then((result) => {
                        if (result && result.unlock == true) {
                            this.$eventHub.$emit("msgSuccess", result && result.message ? result.message : "Enviado com sucesso.");
                            this.$emit("close");
                        } else {
                            this.$eventHub.$emit("msgWarning", result && result.message ? result.message : "Erro ao efetuar comando.");
                        }
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error && error.message ? error.message : "Erro ao enviar, tente novamente.");
                    }).finally(() => {
                        this.sending = false;
                    });
            } else {
                this.$eventHub.$emit("msgError", "Código inválido");
            }
        },
    },
};
</script>