<template>
    <v-chip class="ma-2" :color="card.color" text-color="white" @click="$emit('click')">
        <v-avatar left v-if="card.icon"> <v-icon>{{ card.icon }}</v-icon> </v-avatar> {{ card.msg }}
    </v-chip>
</template>

<script>

export default {
    props: {
        moreInfo: {
            type: Object,
        },
        last4: {},
    },

    computed: {
        card() {
            let ret = { msg: "Erro", icon: "error", color: "red" };

            if (this.moreInfo) {
                if (this.moreInfo.paymentMethod && this.moreInfo.paymentMethod.card) {
                    ret = { icon: "", color: "success", msg: `${this.moreInfo.paymentMethod.card.brand} (${this.moreInfo.paymentMethod.card.last4})` }
                } else if (this.moreInfo.payment_method) {
                    if (this.moreInfo.payment_method == 'credit_card' && this.moreInfo.card) {
                        ret = { icon: "", color: "success", msg: `${this.moreInfo.payment_method.id} (${this.moreInfo.card.last_four_digits})` }
                    } else {
                        ret = { icon: "warning", color: "warning", msg: `(${this.last4})` }
                    }
                }
            }
            return ret
        }
    }
};
</script>